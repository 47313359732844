import { getYear } from 'date-fns'
import React from 'react'
import FormField from '../form/form-field'

const YearSelect = ({ min, defaultValue, onChange }) => {
  const options = React.useMemo(() => {
    const currentYear = getYear(new Date())
    const firstYear = min || getYear(new Date(0))
    const options = []
    for (let year = currentYear; year >= firstYear; year--) {
      options.push({
        label: year,
        value: year
      })
    }
    return options
  }, [min])
  return (<FormField.Select options={options} inputProps={{ onChange: (e) => onChange && onChange(e.target.value), defaultValue: defaultValue || getYear(new Date()) }}/>)
}

export default YearSelect