import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import { Columns, Container, Heading, Section, Table, Element, Loader } from 'react-bulma-components'
import { useOrders, useOrdersRequest } from '../../../stores/orders'
import { useTranslation } from 'react-i18next'
import OrderStatus from '../../../components/orders/status'
import { endOfYear, startOfYear } from 'date-fns'
import YearSelect from '../../../components/year-select'
import { useFirstRender } from '../../../hooks/use-first-render'

const OrdersPage = () => {
  const [state, actions] = useOrders()
  const { t } = useTranslation('orders')
  const firstRender = useFirstRender()

  const [request] = useOrdersRequest({ key: 'get /orders/mafact' })


  React.useEffect(() => {
    if (!state.mafact[0] || !firstRender)
      actions.fetchMafact(startOfYear(new Date(state.filters.year.toString())), endOfYear(new Date(state.filters.year.toString()).getTime()))
  }, [state.filters])

  return (
    <Layout pro>

      <Section>
        <Container>
          <Columns>
            <Columns.Column>
              <Heading size={5} mb={5} textColor='dark' textWeight='normal'>
                {t('title')}
              </Heading>
              <YearSelect min={1990} onChange={(year) => actions.setFilters({ year })} defaultValue={state.filters.year}/>
              <Table.Container>
                <Table textAlign='right' hoverable size='fullwidth'>
                  <thead>
                    <Element renderAs='tr'>
                      <Element renderAs='td'>{t('date')}</Element>
                      <Element renderAs='td'>{t('number')}</Element>
                      <Element renderAs='td' textAlign='center'>{t('status')}</Element>
                      <Element renderAs='td' textAlign='left'>{t('label')}</Element>
                      <Element renderAs='td'>{t('price')}</Element>
                    </Element>
                  </thead>
                  <tbody>
                    {
                      (!request || request.status === 'success') && state.mafact[0] &&
                        state.mafact.map((order) =>
                          <Element renderAs='tr' key={ order._id }>
                            <Element renderAs='td'>{ order.Date }</Element>
                            <Element renderAs='td'>
                              { order.Mafact }
                            </Element>
                            <Element renderAs='td' textAlign='center'>
                              <OrderStatus status={order.Statut}/>
                            </Element>
                            <Element renderAs='td' textAlign='left'>
                              { order.Mafact && <Link to={ `/account/orders/single-order?ref=${order.Mafact}` } className='exalto-link'>
                                {t('order_label')} { order.Date }
                              </Link>}
                            </Element>
                            <Element renderAs='td'>{order.Total}&nbsp;€</Element>
                          </Element>
                        )
                    }
                  </tbody>
                </Table>
              </Table.Container>
              {
                ((!request || request.status === 'inProgress') && !state.mafact[0]) &&
                  <Loader mt={5} style={{ fontSize: 30 }}/>
              }
              {
                request && request.status === 'success' && !state.mafact[0] && <Element textAlign='center'>{t('no_result')} {state?.filters.year}.</Element>
              }
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}

export default OrdersPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`